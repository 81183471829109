<template>
  {{ $t('companyInfoText') }} <a href="#" @click.prevent="openChat">{{ $t('companyInfoLink') }}</a>.
</template>

<script>

export default {
  name: 'senderContactInfoChangeLink',
  props: {
    id: String,
    name: String,
    showDeleteButton: Boolean,
    onClose: Function,
  },
  setup(props) {
    const openChat = () => {
      window.$crisp.push(['do', 'chat:open'])
    }

    return {
      openChat,
    }
  },
}
</script>

<style scoped>

</style>
